import React from 'react';
import { render } from 'react-snapshot';
import 'react-web-vector-icons/fonts';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';

render(<App />, document.getElementById('root'));

serviceWorker.unregister();
