import "isomorphic-fetch";
import React, { Component } from 'react';
import ReactCountryFlag from "react-country-flag";
import ReactFBLike from 'react-fb-like';
import ReactGA from 'react-ga';
import { Browser } from 'react-kawaii';
import { ThreeDots as Loader } from 'react-loader-spinner';
import MetaTags from 'react-meta-tags';
import { Link, withRouter } from 'react-router-dom';
import { snapshot } from 'react-snapshot';
import { FontAwesome } from 'react-web-vector-icons';
import logo from './logo.svg';
import './RadiosPage.css';
import AdSense from 'react-adsense';
import Hls from 'hls.js';

const hostname = (language) => {
  switch (language) {
    case "ro": return "https://asculta.fm";
    case "hu": return "https://magyar-radiok.net";
    case "pl": return "https://sluchaj.fm";
    default: return "";
  }
}
const jsdom = () => navigator && (navigator.userAgent.includes("Node.js") || navigator.userAgent.includes("jsdom"))
const realBrowser = navigator && !navigator.webdriver;

const linkTo = (language, country, radio, withoutLastSlash = false) => {
  country = country || language;
  return /*(window.location.hostname === 'localhost' ? `/${language}` : '') + */`${((language !== country) && ('/' + country)) || ''}${radio ? ('/' + radio) : ''}${(!withoutLastSlash && (jsdom() ? '/' : '')) || ''}`
}

const AdPlaceholder = ({ adSize, adClient, adSlot, key }) => <div key={key} className={"AdPlaceholder " + (adSize || '')}>
  <AdSense.Google
    client={adClient}
    slot={adSlot}
    style={{ display: 'block' }}
    format='auto'
    responsive='true'
  />
  {/* <ins className="adsbygoogle"
    style={{ display: 'block' }}
    data-ad-client={adClient}
    data-ad-slot={adSlot}
    data-ad-format="auto"
    data-full-width-responsive="true"></ins> */}
</div >

const RadioBox = ({ country, language, radio, isSelected }) => {
  return <Link title={radio.name} to={linkTo(language, country, radio.id)} className={"RadioBox " + (isSelected ? 'Selected' : '')} key={radio.id} style={{
    backgroundColor: radio.background || '#74A4C4',
  }}>
    <span className="RadioName" style={{ color: radio.color }}>{radio.name}</span>
    <div className="RadioImage">
      <img alt={radio.name} src={'data:image/png;base64,' + radio.logo} />
    </div>
    {radio.favourite && <span className="RadioFavourite" name="star" style={{ color: radio.color }}></span>}
  </Link>
}

const GdprBanner = ({ translations, onGdprAccepted }) => {
  let { gdprMessage, gdprAccept, gdprPrivacy } = translations;
  gdprMessage = gdprMessage || '';

  return (gdprMessage && (<div className="GdprBanner">
    <p className="Text">{gdprMessage} </p>
    <div class="GdprButtons">
      <div className="AcceptButton" onClick={() => onGdprAccepted(true, true)}>{gdprAccept || 'Accept'}</div>
      <Link className="DenyButton" to="/privacy">{gdprPrivacy || "Privacy"}</Link>
    </div>
  </div>)) || null;
}

const FakeRadioBox = ({ id }) => <div className="RadioBox FakeRadioBox" key={id} />

const CountriesList = ({ countries, language, country }) =>
  (countries && countries.length && (<div className="Countries">
    {countries.map(c => {
      return <Link to={linkTo(language, c.code)} key={c.code}><div style={{ color: 'transparent' }} ><div className={"Country " + ((country === c.code || (!country && language === c.code)) ? 'Selected' : '')}><ReactCountryFlag svg code={c.code} /> {c.name}</div></div></Link>
    })}
  </div>)) || null

class RadioPlayer extends Component {
  state = {
    status: 0,
  }

  constructor(props) {
    super(props);

    setInterval(this.aliveNotification, 1000 * 60);
  }

  aliveNotification = () => {
    if (this.state.status !== 0) {
      global.gaInitialized && ReactGA.pageview('/' + this.props.radio.id);
    }
  }

  initializePlayer = (player) => {
    if (player) {
      this.player = player;
      if (Hls.isSupported()) {
        this.hlsPlayer = new Hls();
        this.hlsPlayer.attachMedia(player);
        this.hlsPlayer.on(Hls.Events.MANIFEST_PARSED, () => {
          player.play();
        });
      }
      this.player.onplaying = (e) => {
        this.setState({ status: 2 })
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.status !== 0 && this.props.radio !== nextProps.radio) {
      this.setState({ status: 0 })
    }
  }

  play = () => {
    try {
      if (this.state.status !== 2) { this.setState({ status: 1 }) };
      if (this.player) {

        const stream = this.props.radio.streams[0];
        if (stream.indexOf('.m3u8') > 0 && !this.player.canPlayType('application/vnd.apple.mpegurl') && this.hlsPlayer) {
          this.hlsPlayer.loadSource(stream);
        } else {
          this.player.src = stream;
        }

        this.player.play().catch(e => console.log(e));
      }
      global.gaInitialized && ReactGA.pageview('/' + this.props.radio.id);
    } catch (e) {

    }
  }

  stop = () => {
    this.setState({ status: 0 });
    this.player && this.player.pause();
  }

  handlePlayPause = () => {
    const { status } = this.state;

    if (status === 0) {
      this.play();
    } else {
      this.stop();
    }
  }

  render() {
    const { radio } = this.props;
    const { status } = this.state;

    return (radio && <div className="Player" style={{ backgroundColor: radio.background, color: radio.color }}>
      <div className="Spacer Mobile Tablet"></div>
      <img alt={radio.name} src={'data:image/png;base64,' + radio.logo} />
      <div className="FlexSpacer" />
      <div className="SocialLinks Tablet Desktop">
        {radio.facebook && realBrowser && <a className="Link" href={radio.facebook} target="_blank" rel="noopener noreferrer">
          <FontAwesome name='facebook' color='inherit' size={20} />
        </a>}
        {radio.twitter && <a className="Link" href={radio.twitter} target="_blank" rel="noopener noreferrer">
          <FontAwesome name='twitter' color='inherit' size={20} />
        </a>}
        {radio.www && <a className="Link" href={radio.www} target="_blank" rel="noopener noreferrer">
          <FontAwesome name='link' color='inherit' size={20} />
        </a>}
      </div>
      <div className="PrevNextButtons">
        <div className="PlayerSquareButton" style={{ borderColor: radio.color }} onClick={this.props.onPrevious}>
          <FontAwesome name='step-backward' color='inherit' size={20} />
        </div>
        <div className="PlayerSquareButton" style={{ borderColor: radio.color }} onClick={this.props.onNext}>
          <FontAwesome name='step-forward' color='inherit' size={20} />
        </div>
      </div>
      <div className="PlayButton" style={{ borderColor: radio.color }} onClick={this.handlePlayPause}>
        {(status === 0) && (<FontAwesome style={{ marginLeft: 5 }} name='play' color='inherit' size={30} />)}
        {(status === 1) && (<Loader wrapperStyle={{ transform: 'scale(0.4)' }} color={radio.color} />)}
        {(status === 2) && (<FontAwesome name='pause' color='inherit' size={30} />)}
      </div>
      <div className="Spacer Mobile Tablet"></div>
      <audio ref={this.initializePlayer}/>
    </div>) || null;
  }
}

class RadiosPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      appName: '',
      gdprAdSenseAccepted: !this.isGdprResponded() || this.isGdprAdSenseAccepted(),
      gdprAnalyticsAccepted: !this.isGdprResponded() || this.isGdprAnalyticsAccepted()
    }
  }

  componentWillMount = () => {
    this.componentWillReceiveProps(this.props);
  }

  componentWillReceiveProps = (nextProps) => {
    let { country, radio } = nextProps.match.params;
    let language = nextProps.language;

    language = language || nextProps.language;

    country = country || language;

    if (country.length > 2) {
      radio = country;
      country = language;
    }

    this.setState({ selectedRadio: null, openMenu: false });

    (snapshot || (func => func()))(() =>
      this.loadPage(language, country)).then(({ radios, settings, error }) => {
        this.setState({
          radios,
          settings,
          error,
          appName: (settings.translations || { name: '' }).name,
          loadedCountry: !error && country
        });

        this.handleSelectedRadio((radio && radios.find(r => r.id === radio)), !radio);
        this.canPlay = true;
        if (this.isGdprAnalyticsAccepted()) {
          this.initializeAnalytics(settings);
        }
      });
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize.bind(this));
  }

  loadPage(language, country) {
    if (this.state.loadedCountry !== country) {
      return Promise.all([this.loadRadios(country || language), this.loadSettings(language)])
        .then(results => ({ radios: results[0], settings: results[1], error: (!results[0].length || !Object.keys(results[1]).length) ? "failed-to-load" : null }))
    } else {
      return new Promise(resolve => resolve({ radios: this.state.radios, settings: this.state.settings }));
    }
  }

  loadRadios(country) {
    return fetch(`/data/${country}/radios.json`).then(response => response.json()).catch(_ => []);
  }

  loadSettings(language) {
    return fetch(`/data/${language}/settings.json`).then(response => response.json()).catch(_ => ({}));
  }

  initializeAnalytics = (settings) => {
    if (jsdom()) return;

    if (!global.gaInitialized) {
      ReactGA.initialize(settings.tracking);
      global.gaInitialized = true;
    }
  }

  initializePersonalizedAds = (settings) => {

  }

  handleSelectedRadio = (selectedRadio, doNotPlay) => {
    if (selectedRadio) {
      this.setState({ selectedRadio });
      this.canPlay && !jsdom() && !doNotPlay && setTimeout(() => this.player && this.player.play(), 200);
    }
  }

  handleNext = () => {
    const { selectedRadio, radios } = this.state;

    const index = radios.indexOf(selectedRadio);

    if (index + 1 < radios.length) {
      this.props.history.push(radios[index + 1].id)
    } else {
      this.props.history.push(radios[0].id)
    }
  }

  handlePrevious = () => {
    const { selectedRadio, radios } = this.state;

    const index = radios.indexOf(selectedRadio);

    if (index > 0) {
      this.props.history.push(radios[index - 1].id)
    } else {
      this.props.history.push(radios[radios.length - 1].id)
    }
  }

  handleToggleMenu = (e) => {
    this.setState({ openMenu: !this.state.openMenu });
    e && e.stopPropagation();
  }

  handleHideMenu = () => {
    this.setState({ openMenu: false });
  }

  handleResize = () => {
    const width = Math.min(window.innerWidth, 1000);
    const largeDesktop = window.innerWidth > 1400;

    let radiosPerRow;
    let adSize;
    let adDensity;

    if (width < 618) {
      radiosPerRow = Math.floor(width / (110 + 14));
    } else {
      radiosPerRow = Math.floor(width / (140 + 14));
    }

    if (width < 468) {
      adSize = "Small";
    } else if (width >= 468 && width < 800) {
      adSize = "Medium";
    } else {
      adSize = "Large";
    }

    if (radiosPerRow <= 4) {
      adDensity = 5;
    } else if (radiosPerRow <= 5) {
      adDensity = 6;
    } else {
      if (largeDesktop) {
        adDensity = 8;
      } else {
        adDensity = 8;
      }
    }

    if (this.state.radiosPerRow !== radiosPerRow ||
      this.state.adSize !== adSize ||
      this.state.adDensity !== adDensity ||
      this.state.largeDesktop !== largeDesktop) {
      this.setState({ largeDesktop, radiosPerRow, adSize, adDensity });
    }
  }

  handleStopScroll = (event) => {
    event.stopPropagation();
    event.preventDefault();
  }

  gdprAccepted = (analytics, adSense) => {
    window.localStorage.setItem("GDPR_RESPONDDED", true);
    window.localStorage.setItem("GDPR_ADSENSE", adSense + '');
    window.localStorage.setItem("GDPR_ANALYTICS", analytics + '');

    if (window.location.pathname.indexOf("/privacy") === 0) {
      window.location.pathname = "/";
    } else {
      window.location.reload();
    }
  }

  isGdprAnalyticsAccepted = () => window && window.localStorage && window.localStorage.getItem("GDPR_ANALYTICS") === 'true';

  isGdprAdSenseAccepted = () => window && window.localStorage.getItem("GDPR_ADSENSE") === 'true';

  isGdprResponded = () => window && window.localStorage && !!window.localStorage.getItem("GDPR_RESPONDDED");

  handleStateChange = (state, value) => {
    this.setState({ [state]: value });
  }

  render() {
    const { radios, settings, selectedRadio, appName, openMenu, error, radiosPerRow, adSize, adDensity, largeDesktop, gdprAnalyticsAccepted, gdprAdSenseAccepted } = this.state;
    let { countries, facebookLanguage, facebookAppId, translations, adUnit, facebookLikePage } = settings || {};
    let { country, language, radio } = this.props.match.params;
    let [adClient, adSlot] = (adUnit || "").split('/');
    let { privacy } = this.props;
    language = language || this.props.language;

    translations = translations || {
      error: 'Ups, something went wrong :(',
      tryAgain: 'Try again',
    }

    country = country || language;

    if (country.length > 2) {
      radio = country
      country = language;
    }

    const radioLink = this.props.match.params.radio || (this.props.match.params.country && this.props.match.params.country.length > 2);
    const htmlTitle = ((radioLink ? translations.titleRadio : translations.title) || '').replace("{COUNTRY}", ((countries || []).find(c => c.code === country) || { name: country }).name).replace("{RADIO}", (selectedRadio || { name: '' }).name);
    const htmlDescription = (translations.decription || '').replace("{COUNTRY}", ((countries || []).find(c => c.code === country) || { name: country }).name).replace("{RADIO}", (selectedRadio || { name: '' }).name);

    let RadiosList = []

    if (!privacy && radios) {
      (radios || []).forEach((radio, index) => {
        if (adDensity && adClient && adSize && realBrowser && radiosPerRow && (((index / radiosPerRow) - 1) % adDensity) === 0 && index % radiosPerRow === 0) {
          RadiosList.push(AdPlaceholder({ key: RadiosList.length, adSize, adSlot, adClient }));
        }
        RadiosList.push(RadioBox({ language, country, radio, isSelected: selectedRadio === radio, onSelected: this.handleSelectedRadio }));
      });

      if (realBrowser && radiosPerRow && radios.length % radiosPerRow) {
        [1, 2, 3, 4, 5, 6, 7, 8, 9].slice(0, radiosPerRow - (radios.length % radiosPerRow)).forEach(id => RadiosList.push(FakeRadioBox({ id })));
      } else {
        [11, 12, 13, 14, 15, 16].forEach(id => RadiosList.push(FakeRadioBox({ id })));
      }
    }


    const pageSnapshot = hostname(language) + linkTo(language, country, radio) + (jsdom() ? '' : '/') + 'snapshot.png';
    const pageUrl = hostname(language) + linkTo(language, country, radio);
    const ogImageWidth = radio ? 200 : 600;
    const ogImageHeight = radio ? 200 : 315;
    return (
      <div className="MainPage" >
        <MetaTags>
          <title>{htmlTitle}</title>
          <meta name="description" content={htmlDescription} />
          <meta name="keywords" content={translations.keywords} />
          <meta property="image" content={pageSnapshot} />
          <meta property="og:title" content={htmlTitle} />
          <meta property="og:description" content={htmlTitle} />
          <meta property="og:url" content={pageUrl} />
          <meta property="og:image" content={pageSnapshot} />
          <meta property="og:image:alt" content={htmlTitle} />
          <meta property="og:type" content="music.radio_statio n" />
          <meta property="fb:app_id" content={facebookAppId} />
          <meta property="og:image:width" content={ogImageWidth} />
          <meta property="og:image:height" content={ogImageHeight} />
        </MetaTags>
        {!error && !privacy && realBrowser && adClient && adSlot && largeDesktop && <div className="LargeDesktop VerticalAd"><AdPlaceholder adSize="Vertical" adClient={adClient} adSlot={adSlot}></AdPlaceholder></div>}
        <div className="MainPageContent">
          <div className="Header Desktop ">
            <Link to={linkTo(language, country)} className="LogoLink">
              <ReactCountryFlag code={country} svg />
              <img alt={`${appName} logo`} className="Logo" src={logo}></img>
            </Link>
            {!jsdom() && !error && <div className="FacebookWidget">
              {facebookAppId && <ReactFBLike language={facebookLanguage} appId={facebookAppId} version="v2.12" href={facebookLikePage || (window.location.protocol + '//' + window.location.host)} layout="button_count" />}
            </div>}
            {!error && <div className="FlexSpacer"></div>}
            {privacy && <Link to={linkTo(language, country)} className="TermsAndConditionsCloseButton">
              <FontAwesome name='close' color='inherit' size={20} />
            </Link>}
          </div>

          <div className={"RadioPlayer " + (!realBrowser ? 'ForSnapshot' : '')}>
            <RadioPlayer radio={selectedRadio} ref={player => this.player = player} onPrevious={this.handlePrevious} onNext={this.handleNext} />
          </div>

          <div className="Header Mobile Tablet" onClick={this.handleHideMenu} >
            {realBrowser && <div onClick={this.handleToggleMenu} className="MenuButton"><FontAwesome name='bars' color='inherit' size={20} /></div>}
            <div className="FlexSpacer"></div>
            <Link to={linkTo(language, country)} className="LogoLink">
              {/* <ReactCountryFlag code={country} svg /> */}
              <img alt={`${appName} logo`} className="Logo" src={logo}></img>
            </Link>
            {!realBrowser && !radio && <span className="AppTitle">{appName}</span>}
            <div className="FlexSpacer"></div>
            {realBrowser && <div className="MenuButton">
              {privacy && <Link to={linkTo(language, country)} className="TermsAndConditionsCloseButton">
                <FontAwesome name='close' color='inherit' size={20} />
              </Link>}
            </div>}
          </div>

          <div className="PageContent">
            {error && <div className="ErrorContainer">
              <Browser size={200} mood="sad" color="#FDA7DC" />
              <span className="Error">{translations.error}</span>
              <div className="TryAgain" onClick={() => this.loadPage(language, country)}>{translations.tryAgain}</div>
            </div>}
            {!error && !privacy && <div className="RadiosContainer">
              <div className="Spacer Mobile Tablet"></div>
              {RadiosList}
              {radio && <div className="Spacer Mobile Tablet"></div>}
            </div>}
            {!error && privacy && <div className="TermsAndConditions">
              <div dangerouslySetInnerHTML={{ __html: translations.termsAndConditions }} />
              <div className="OptInOutContainer">
                <h2>{translations.gdprPrivacy || 'Privacy'}</h2>
                <div className="OptInOut"><p>Goolge Analytics</p><span> {translations.gdprGoogleAnalytics || 'We use Google Analytics to count how many users are using this app as well as to see what are the most listened radio stations in order to improve our selection of radio stations. You can at any moment decide that you don\'t want to be counted by unselecting the checkbox from right side of the screen.'}</span><input type="checkbox" onChange={e => this.handleStateChange("gdprAnalyticsAccepted", !gdprAnalyticsAccepted)} checked={gdprAnalyticsAccepted}></input></div>
                <div className="OptInOut"><p>Google AdSense</p><span> {translations.gdprGoogleAdSense || 'We use Google AdSense to display ads that generate revenue in order to sustain this project. It uses cookies to show you relavant ads. If you decide that you don\'t want to receive targeted ads, then you can unselect the checkbox from the right side of the screen.'}</span><input type="checkbox" onChange={e => this.handleStateChange("gdprAdSenseAccepted", !gdprAdSenseAccepted)} checked={gdprAdSenseAccepted}></input></div>
                <div className="AcceptButton" onClick={() => this.gdprAccepted(gdprAnalyticsAccepted, gdprAdSenseAccepted)}>{translations.gdprSavePreferences || 'Save preferences'}</div>
              </div>
            </div>}
          </div>
          {!error && realBrowser && settings && <div className="Footer Desktop">
            <CountriesList countries={countries} country={country} language={language} />
            {
              (settings.androidApp || settings.iosApp) && <div className="MobileApps">
                {settings.iosApp && <a className="IosApp" href={settings.iosApp}><img alt={`Apple badge for  ${appName}`} className="AppBadge" src="/apple-badge.png"></img></a>}
                {settings.androidApp && <a className="AndroidApp" href={settings.androidApp}><img alt={`Android badge for  ${appName}`} className="AppBadge" src="/android-badge.png"></img></a>}
              </div>
            }
            <div className="ContactPrivacyBar">
              {settings.contact && <a href={settings.contact}>{translations.contactUs || "Contact us"}</a>}
              <Link to="/privacy">{translations.gdprPrivacy || "Privacy"}</Link>
              {settings.facebookPage && <a target="_blank" rel="noopener noreferrer" href={settings.facebookPage}>Facebook</a>}
            </div>
          </div>}
          {settings && <div className={"DrawerMenu Mobile Tablet " + (openMenu ? 'Visible' : 'Hidden') + (radio ? ' WithRadio' : ' NoRadioSelected')} onClick={this.handleToggleMenu} >
            <div className="DrawerMenuContainer" onClick={e => e.stopPropagation()} onTouchStart={e => this.handleStopScroll(e)}>
              <div className="DrawerMenuContent">
                <span className="CountriesLabel">{translations.countries}</span>
                <CountriesList countries={countries} country={country} language={language} />
                <div className="ContactPrivacyBar">
                  <Link to={"/privacy" + (jsdom ? '/' : '')} onClick={this.handleToggleMenu}>{translations.gdprPrivacy || "Privacy"}</Link>
                  {settings.facebookPage && <a target="_blank" rel="noopener noreferrer" href={settings.facebookPage}>Facebook</a>}
                  {settings.contact && <a rel="noopener noreferrer"  href={settings.contact}>{translations.contactUs || "Contact us"}</a>}
                </div>
                {!jsdom() && <div className="FacebookWidget">
                  {facebookAppId && <ReactFBLike language={facebookLanguage} appId={facebookAppId} version="v2.12" href={facebookLikePage || (window.location.protocol + '//' + window.location.host)} layout="button_count" />}
                </div>}
              </div>
            </div>
          </div>}
        </div>
        {!error && !privacy && realBrowser && adClient && adSlot && largeDesktop && <div className="LargeDesktop VerticalAd"><AdPlaceholder adSize="Vertical" adClient={adClient} adSlot={adSlot}></AdPlaceholder></div>}
        {!jsdom() && realBrowser && !this.isGdprResponded() && <GdprBanner translations={translations} onGdprAccepted={this.gdprAccepted}></GdprBanner>}
      </div>
    );
  }
}

export default withRouter(RadiosPage);
