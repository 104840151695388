import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './App.css';
import RadiosPage from './RadiosPage';


class App extends Component {
  render() {
    var language = process.env.REACT_APP_LANGUAGE;

    if (!language) {
      if (window.location.host.indexOf('magyar-radiok.net') >= 0) language = "hu";
      if (window.location.host.indexOf('asculta.fm') >= 0) language = "ro";
      if (window.location.host.indexOf('sluchaj.fm') >= 0) language = "pl";
      if (window.location.host.indexOf('moldova.asculta.fm') >= 0) language = "md";
      if (window.location.host.indexOf('deutschland.asculta.fm') >= 0) language = "de";
    }

    language = language || 'ro';

    return (
      <Router>
        <Switch>
          <Route exact path="/privacy" render={(props) => <RadiosPage {...props} language={language} privacy={true} />} />
          <Route exact path="/:country/:radio" render={(props) => <RadiosPage {...props} language={language} />} />
          <Route exact path="/:country/" render={(props) => <RadiosPage {...props} language={language} />} />
          <Route exact path="/" render={(props) => <RadiosPage {...props} language={language} />} />
        </Switch>
      </Router>
    );
  }
}

export default App;
